<template>
  <section class="agency-signUp-main-sec">
    <div class="container">
      <div class="row">
        <div class="col-md-5">
          <div class="responsive-logo">
            <a href="#"><img src="@/assets/common/images/lbhq-logo.svg" /></a>
          </div>
          <div class="form-box signIn-form-box">
            <div class="text-heading">Sign In</div>
            <form>
              <div class="form-group">
                <label for="exampleInputEmail1">Email</label>
                <input
                  type="email"
                  @keyup.enter="handleEnterKey"
                  class="form-control"
                  v-model="email"
                  id="exampleInputEmail1"
                />
                <span
                  v-if="errors.email"
                  class="invalid-feedback"
                  style="display: block"
                  role="alert"
                >
                  <strong>{{ errors.email[0] }}</strong>
                </span>
              </div>
              <div class="form-group">
                <label for="exampleInputPassword1">Password</label>
                <input
                  v-bind:type="[showPassword ? 'text' : 'password']"
                  @keyup.enter="handleEnterKey"
                  v-model="password"
                  class="form-control"
                  id="exampleInputPassword1"
                />
                <span class="eye-icon" @click="showPassword = !showPassword">
                  <i
                    class="fa"
                    :class="[showPassword ? 'fa-eye-slash' : 'fa-eye']"
                  ></i>
                </span>

                <span
                  v-if="errors.password"
                  class="invalid-feedback"
                  style="display: block"
                  role="alert"
                >
                  <strong>{{ errors.password[0] }}</strong>
                </span>
              </div>
              <div class="form-group row signIn-field">
                <div class="col">
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      v-model="remember"
                      type="checkbox"
                      id="gridCheck"
                    />
                    <label class="form-check-label" for="gridCheck">
                      Remember me
                    </label>
                  </div>
                </div>

                <div class="col">
                  <router-link to="/forget-password"
                    >Forgot Password?</router-link
                  >
                </div>
              </div>
              <button
                type="button"
                class="btn-1"
                @click="login"
                :class="{ disabled: !seen }"
              >
                {{ button.text }}
                <b-spinner small v-show="isLoading"></b-spinner>
              </button>

              <div class="sign-in-text">
                Dont't have an account?
                <!-- <router-link to="/register">Sign Up</router-link> -->
                <router-link :to="queryParam ? `register${queryParamValue}` : 'register'">Sign Up</router-link>
              </div>
            </form>
          </div>
        </div>

        <div class="col-md-7">
          <ContentBoxComponent></ContentBoxComponent>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { useToast } from "vue-toastification";
import ContentBoxComponent from "@/components/ContentBoxComponent.vue";
const toast = useToast();

export default {
  name: "LoginView",
  components: {
    ContentBoxComponent,
  },
  async mounted() {
    this.$gtag = window.gtag;
    this.$gtag('get', 'G-K51T6T1GCD', 'client_id', (clientID) => {
      if(clientID){
        this.effectiveUserId = clientID;
      }
    });
    
    this.checkUrlParams();

    const url = new URL(window.location.href);
    const searchParams = new URLSearchParams(url.search);
    const type = searchParams.get("type");
    if (type == "impersonate") {
      var userId = searchParams.get("user_id");
      var token = searchParams.get("token");
      this.impersonateLogin(userId, token, type);
    }
  },
  data() {
    return {
      email: "",
      password: "",
      remember: false,
      isLoading: false,
      errors: [],
      button: {
        text: "Login",
      },
      seen: true,
      showPassword: false,
      queryParam: false,
      queryParamValue: '',
      effectiveUserId: '',
      data: {
        verification_code: ''
      }
    };
  },

  methods: {
    handleEnterKey() {
      this.login();
    },
    getQueryParameterValue(parameterName) {
      var queryString = location.search.substring(1);
      var queryParams = queryString.split("&");

      for (var i = 0; i < queryParams.length; i++) {
        var param = queryParams[i].split("=");
        if (param[0] === parameterName) {
          return decodeURIComponent(param[1]);
        }
      }
      return null;
    },
    checkUrlParams() {
      var daValue = this.getQueryParameterValue("da");
      var categoryValue = this.getQueryParameterValue("category");
      var sourceValue = this.getQueryParameterValue("utm_source");
      var mediumValue = this.getQueryParameterValue("utm_medium");
      var campaignValue = this.getQueryParameterValue("utm_campaign");
      var webOrder = this.getQueryParameterValue("order");

      if (webOrder !== null) {
        this.queryParamValue = `?order=${webOrder}`;
        if(sourceValue){
          this.queryParamValue += `&utm_source=${sourceValue}`;
        }
        if(mediumValue){
          this.queryParamValue += `&utm_medium=${mediumValue}`;
        }
        if(campaignValue){
          this.queryParamValue += `&utm_campaign=${campaignValue}`;
        }
        this.queryParam = true;
      }

      if (daValue !== null && categoryValue !== null) {
        this.queryParamValue = `?da=${daValue}&category=${categoryValue}`;
        if(sourceValue){
          this.queryParamValue += `&utm_source=${sourceValue}`;
        }
        if(mediumValue){
          this.queryParamValue += `&utm_medium=${mediumValue}`;
        }
        if(campaignValue){
          this.queryParamValue += `&utm_campaign=${campaignValue}`;
        }
        this.queryParam = true;
      }

      if(this.$route.query.verification_code){
        this.data.verification_code = this.$route.query.verification_code;
        this.verification();
      }
    },
    async login() {
      this.isLoading = true;
      try {
        let res = await this.$store.dispatch("Auth/loginAction", {
          email: this.email,
          password: this.password,
          remember: this.remember,
          effective_user_id: this.effectiveUserId,
        });
        // if (res.success == false && res.data.is_email_verified == false) {
        //   this.isLoading = false;
        //   localStorage.setItem("email", this.email);
        //   this.$router.push("/email-verification");
        // } else if (res.success) {
        //   this.isLoading = false;
        //   toast.success(res.message, { timeout: 3000 });

        //   if(this.queryParam){
        //     this.$router.push(`/product${this.queryParamValue}`);
        //   }else{
        //     this.$router.push("/dashboard");
        //   }
        // }
        this.isLoading = false;
        toast.success(res.message, { timeout: 3000 });

        if(this.queryParam){
          this.$router.push(`/product${this.queryParamValue}`);
        }else{
          this.$router.push("/dashboard");
        }
      } catch (error) {
        this.isLoading = false;
        this.errors = error.response.data.errors;
      }
    },
    async verification() {
      this.loading = true;
      try {
        let res = await this.$store.dispatch(
          "Auth/isEmailVerifiedAction",
          {
            'verification_code' : this.data.verification_code
          },
          {
            root: true,
          }
        );
        if(res.success){
          this.confirmShowBox = false;
        }
        this.loading = false;
        this.$router.push("/dashboard");
        toast.success(res.message, { timeout: 3000 });
      } catch (error) {
          this.loading = false;
          toast.error(error.response.data.errors, { timeout: 3000 });
          this.errors = error.response.data.errors;
      }
    },
    async impersonateLogin(userId, token, type) {
      this.isLoading = true;
      try {
        let res = await this.$store.dispatch("Auth/impersonateLoginAction", {
          token: token,
          user_id: userId,
        });

        if (res.success) {
          localStorage.setItem("impersonate-login", type);
          this.isLoading = false;
          toast.success(res.message, { timeout: 3000 });
          this.$router.push("/dashboard");
        }
      } catch (error) {
        this.isLoading = false;
        toast.error(error.response.data.errors, { timeout: 3000 });
      }
    },
  },
};
</script>
