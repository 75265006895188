<template>
  <div class="home">
    <LoaderComponent v-show="loaded" :loaderText="loaderText" />
    <HeaderView />
    <section class="order-page-main-sec agency-design-grid-right">
      <div class="desktop-respovsive-main-sec">
        <div class="place-order-top-sec">
          <div class="order-list-tabs">
            <div class="links-tabs">
              <h2 class="text-heading">Place your Order</h2>
              <ul>
                <li>
                  <router-link
                    to="/product"
                    class="router-link-active router-link-exact-active"
                    >1. Place Order <i class="fas fa-caret-right"></i>
                    <span class="bottom-bar"></span>
                  </router-link>
                </li>
                <li>
                  <router-link to="/"
                    >2. Payment <i class="fas fa-caret-right"></i>
                  </router-link>
                </li>
                <!-- <li>
        <router-link to="\">3. Confirmation</router-link>
      </li> -->
              </ul>
            </div>
            <div
              class="order-input-field"
              :class="{ 'order-title-error': errors.order_title }"
            >
              <div class="form-group">
                <label>Order Title*</label>
                <input
                  type="text"
                  class="form-control"
                  id="formGroupExampleInput"
                  maxlength="100"
                  placeholder="Add any title here, that will help you track this order later"
                  v-model="form.order_title"
                  :class="{
                    'has-error border-danger':
                      form.order_title.length > 0 ? '' : errors.order_title,
                  }"
                />
                <span
                  v-if="errors.order_title"
                  class="invalid-feedback"
                  style="display: block"
                  role="alert"
                >
                  <strong>{{
                    form.order_title.length > 0 ? "" : errors.order_title[0]
                  }}</strong>
                </span>
              </div>
            </div>
          </div>
          <OrderTotalBoxComponent :packageOrder="packageOrder" :da_20_count="da_20_count" :da_20_total="da_20_total" :da_30_count="da_30_count" :da_30_total="da_30_total"
          :da_40_count="da_40_count" :da_40_total="da_40_total" :da_50_count="da_50_count" :da_50_total="da_50_total"
          :da_60_count="da_60_count" :da_60_total="da_60_total" :links="links" :link_total="link_total" :addon_count="addon_count"
          :addon_total="addon_total" :total="total" :activeProduct="activeProduct" :showProceed="false"
          @submit="submit"></OrderTotalBoxComponent>
        </div>
        <div class="editor-dashboard-sec-2" :class="{ packageOrderActive: packageOrder }">
          <div class="table-responsive">
            <form method="POST" @submit.prevent="onSubmit">
              <table class="addon-table" >
                <tr class="editor-article-field-1">
                  <th>
                    DA*
                    <i
                      class="fas fa-exclamation-circle"
                      v-b-tooltip.hover.top="
                        'Domain Authority is a score that measures the ranking power of a website!'
                      "
                    ></i>
                  </th>
                  <th>
                    Primary Category*
                    <i
                      class="fas fa-exclamation-circle"
                      v-b-tooltip.hover.top="
                        'Primary category refers to the main niche that your website belongs to!'
                      "
                    ></i>
                  </th>
                  <th>
                    Secondary Category*
                    <i
                      class="fas fa-exclamation-circle"
                      v-b-tooltip.hover.top="
                        'Secondary category refers to the sub or partial niche that your website may belong to!'
                      "
                    ></i>
                  </th>
                  <th>
                    Anchor Text*
                    <i
                      class="fas fa-exclamation-circle"
                      v-b-tooltip.hover.top="
                        'Anchor Text is the visible, clickable text on a hyperlink!'
                      "
                    ></i>
                  </th>
                  <th>
                    Destination URL*
                    <i
                      class="fas fa-exclamation-circle"
                      v-b-tooltip.hover.top="
                        'The destination URL or the final URL is the page that users land on after clicking your link!'
                      "
                    ></i>
                  </th>
                  <th v-if="!packageOrder">
                    <span>Addon</span> Minimum Traffic
                    <i
                      class="fas fa-exclamation-circle"
                      v-b-tooltip.hover.top="
                        'Choose the monthly traffic of your prospective publication. The higher their traffic the more referral traffic you are likely to get!'
                      "
                    ></i>
                  </th>
                  <th v-if="!packageOrder">
                    <span>Addon</span> Content Approval
                    <i
                      class="fas fa-exclamation-circle"
                      v-b-tooltip.hover.top="
                        'Make the final approval on the content before it is published!'
                      "
                    ></i>
                  </th>
                  <!-- <th>
                          <span>Prospect Approval</span>
                          Addon
                          <i
                            class="fas fa-exclamation-circle"
                            v-b-modal.modal-box-3
                          ></i>
                        </th> -->
                  <th v-if="!packageOrder">
                    Price
                    <!-- <i class="fas fa-exclamation-circle" v-b-modal.modal-box-3 v-b-tooltip.hover.top="'Price'"></i> -->
                  </th>
                </tr>
                <tr
                  v-for="(link, key) in links"
                  :class="['tabitemfield' + key]"
                  :key="link.id"
                >
                  <td
                    v-if="validations.links[key]"
                    :class="{
                      'has-error border-danger':
                        validations.links[key].product.is_valid,
                    }"
                  >
                    <div class="select-box">
                      <select
                        v-model="links[key].product"
                        @change="
                          setPD($event.target.value, key);
                          getCategories(key);
                        "
                        class="product_dropdown form-control"
                        :disabled="packageOrder"
                      >
                        <option
                          v-if="validations.links[key].product.is_valid"
                          disabled
                          hidden
                          value="null"
                        >
                          {{
                            validations.links[key].product.is_valid
                              ? "Select DA"
                              : validations.links[key].product.error
                          }}
                        </option>
                        <option value="null" disabled hidden>Select DA</option>
                        <option
                          v-for="(pd, k) in products"
                          :key="k"
                          :value="pd"
                          :disabled="packageOrder && pd.name != package_product"
                        >
                          {{ pd.name }}
                        </option>
                      </select>
                    </div>
                  </td>
                  <td
                    :class="{
                      'has-error border-danger':
                        validations.links[key].primary_category.is_valid,
                    }"
                  >
                    <div class="select-box">
                      <select
                        v-model="links[key].primary_category"
                        @change="
                          setPC($event.target.value, key);
                          setSelected(key);
                          getAddons(key);
                        "
                        class="category_dropdown form-control"
                        :disabled="packageOrder"
                      >
                        <option
                          v-if="
                            validations.links[key].primary_category.is_valid
                          "
                          disabled
                          hidden
                          value="null"
                        >
                          {{
                            validations.links[key].primary_category.is_valid
                              ? "Select Category"
                              : validations.links[key].primary_category.error
                          }}
                        </option>
                        <option disabled hidden value="null">
                          Primary Category
                        </option>
                        <option
                          v-for="p in categories[key]"
                          :key="p.id"
                          :value="p"
                          :disabled="packageOrder && p.name != package_category"
                        >
                          {{ p.name }} (${{ (packageOrder && p.name == package_category ? (package_price / package_links).toFixed(2) : p.total_cost) }})
                        </option>
                      </select>
                    </div>
                  </td>
                  <td
                    :class="{
                      'has-error border-danger':
                        validations.links[key].secondry_category.is_valid,
                    }"
                  >
                    <input
                      type="text"
                      id="name"
                      v-model="links[key].secondry_category"
                      @input="setSC($event.target.value, key)"
                      class="form-control"
                      :placeholder="
                        validations.links[key].secondry_category.is_valid
                          ? 'Enter Secondary Category'
                          : 'Enter Secondary Category'
                      "
                    />
                    <!-- <span v-if="validations.links[key].secondry_category.is_valid" class="invalid-feedback" style="display: block" role="alert"><strong>Enter Secondary Category</strong></span> -->
                  </td>
                  <td
                    :class="{
                      'has-error border-danger':
                        validations.links[key].text.is_valid,
                    }"
                  >
                    <input
                      type="text"
                      v-model="links[key].text"
                      @input="setText($event.target.value, key)"
                      class="form-control"
                      :placeholder="
                        validations.links[key].text.is_valid
                          ? 'Enter Anchor Text'
                          : 'Enter Anchor Text'
                      "
                    />
                  </td>
                  <td
                    :class="{
                      'has-error border-danger':
                        validations.links[key].url.is_valid,
                    }"
                  >
                    <input
                      type="text"
                      v-model="links[key].url"
                      @input="setUrl($event.target.value, key)"
                      class="form-control"
                      :placeholder="
                        validations.links[key].url.is_valid
                          ? 'Enter Destination Url'
                          : 'Enter Destination Url'
                      "
                    />
                  </td>
                  <td v-if="!packageOrder"
                    :class="{
                      minimumTraffic:
                        addon_da_options[key] &&
                        addon_da_options[key].length == 0,
                    }"
                  >
                    <div v-for="(pd, s) in addon_products" :key="s">
                      <template
                        v-if="
                          addon_da_options[key] &&
                          addon_da_options[key].length == 0
                        "
                      >
                        <div
                          class="disabled_minimumTrafic"
                          v-if="pd.type == 'multiple'"
                        >
                          <span>N/A</span>
                        </div>
                      </template>
                      <template v-else-if="addon_da_options[key] !== undefined">
                        <div
                          class="per-15 addons"
                          v-if="
                            pd.dependent_da == 'yes' &&
                            addon_da_options[key] !== undefined &&
                            links[key].isDaAddonActive
                          "
                        >
                          <div
                            class="addon_multiple select-box"
                            v-if="pd.type == 'multiple'"
                          >
                            <select
                              :disabled="links[key].isDaAddonFixed"
                              v-model="links[key].da_fixed_addons"
                              v-if="pd.price_type == 'fixed'"
                              class="form-control"
                              @change="
                                setSelectedMAddon(
                                  pd.id,
                                  key,
                                  $event.target.value
                                );
                                setMA($event.target.value, key);
                              "
                            >
                              <option value="null" selected>
                                {{ pd.name }}
                              </option>
                              <option
                                v-for="(option, a) in addon_da_options[key]"
                                :v-if="option.addon_id == pd.id"
                                :key="a"
                                v-bind:value="JSON.stringify(option)"
                              >
                                {{ option.name }} (${{ option.cost }})
                              </option>
                            </select>
                            <select
                              :disabled="links[key].isDaAddonPercentage"
                              v-else
                              v-model="links[key].da_percentage_addons"
                              class="form-control"
                              @change="
                                setSelectedMAddon(
                                  pd.id,
                                  key,
                                  $event.target.value
                                );
                                setMA($event.target.value, key);
                              "
                            >
                              <option value="null" selected>
                                {{ pd.name }}
                              </option>
                              <option
                                v-for="(option, a) in addon_da_options[key]"
                                :v-if="option.addon_id == pd.id"
                                :key="a"
                                v-bind:value="JSON.stringify(option)"
                              >
                                {{ option.name }} (${{ option.cost }})
                              </option>
                            </select>
                          </div>
                        </div>
                      </template>
                    </div>
                  </td>
                  <td class="content-approval" v-if="!packageOrder">
                    <div v-for="(pd, s) in addon_products" :key="s">
                      <div
                        class="per-15 addons"
                        v-if="
                          pd.dependent_da == 'yes' &&
                          addon_da_options[key] !== undefined &&
                          links[key].isDaAddonActive
                        "
                      >
                        <div class="addon_single" v-if="pd.type == 'single'">
                          <div
                            class="checkbox"
                            v-for="(option, j) in addon_da_options[key]"
                            :key="j"
                            :v-if="option.addon_id == pd.id"
                          >
                            <div v-if="pd.price_type == 'fixed'">
                              <div class="checkboxBorder">
                                <input
                                  class="selectedCheckbox"
                                  type="checkbox"
                                  :key="j"
                                  :value="option"
                                  @click="
                                    setSelectedSAddon(
                                      key,
                                      $event.target.checked,
                                      option
                                    );
                                    setSA($event.target.value, key);
                                  "
                                />
                                <span class="check-text">
                                  {{ option.name }} (${{ option.cost }})
                                </span>
                              </div>
                            </div>
                            <div v-else>
                              <div class="checkboxBorder">
                                <input
                                  class="selectedCheckbox"
                                  v-model="links[key].addons"
                                  type="checkbox"
                                  :key="j"
                                  :value="option"
                                  @click="
                                    setSelectedSAddon(
                                      key,
                                      $event.target.checked,
                                      option
                                    );
                                    setSA($event.target.value, key);
                                  "
                                />
                                <span class="check-text">
                                  {{ option.name }} (${{ option.cost }})
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div
                        class="per-15 addons"
                        v-if="
                          pd.dependent_da == 'no' &&
                          addon_options[key] !== undefined &&
                          links[key].isAddonActive
                        "
                      >
                        <div class="addon_single" v-if="pd.type == 'single'">
                          <div
                            class="checkbox"
                            v-for="(option, j) in addon_options[key]"
                            :key="j"
                            :v-if="option.addon_id == pd.id"
                          >
                            <div v-if="pd.price_type == 'fixed'">
                              <div class="checkboxBorder">
                                <input
                                  class="selectedCheckbox"
                                  v-model="links[key].addons"
                                  type="checkbox"
                                  :key="j"
                                  :value="option"
                                  @click="
                                    setSelectedNDSSAddon(
                                      key,
                                      $event.target.checked,
                                      option
                                    );
                                    setNDSA($event.target.value, key);
                                  "
                                />
                                <span class="check-text">
                                  {{ option.name }} (${{ option.cost }})
                                </span>
                              </div>
                            </div>
                            <div v-else>
                              <div class="checkboxBorder">
                                <input
                                  class="selectedCheckbox"
                                  v-model="links[key].addons"
                                  type="checkbox"
                                  :key="j"
                                  :value="option"
                                  @click="
                                    setSelectedNDSSAddon(
                                      key,
                                      $event.target.checked,
                                      option
                                    );
                                    setNDSA($event.target.value, key);
                                  "
                                />
                                <span class="check-text">
                                  {{ option.name }} (${{ option.cost }})
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </td>

                  <td v-if="!packageOrder">
                    <div class="text-1">
                      <b>${{ (packageOrder ? (package_price / package_links).toFixed(2) : link.total) }}</b>
                    </div>
                  </td>
                  <td v-if="!packageOrder">
                    <div class="btn-box">
                      <a
                        href="javascript:;"
                        :class="{ disabled: links[key].isCopy }"
                        v-if="links[key]"
                        ><i @click="copyLink(key)" class="fas fa-copy"></i
                      ></a>
                      <a href="javascript:;" @click="removeLink(key)"
                        ><i class="fas fa-trash-alt"></i
                      ></a>
                    </div>
                  </td>
                </tr>
              </table>
            </form>
          </div>

          <div class="proceed-box">
            <div class="btn-box-list">
              <a href="javascript:;" v-if="!packageOrder" @click="addLink" class="btn-1"
                ><i class="fas fa-plus"></i> Add another Link</a
              >
              <a href="javascript:;" v-if="!packageOrder" class="btn-2" v-b-modal.modal-box-1
                >Upload CSV for Bulk Order
                <i
                  class="fas fa-exclamation-circle"
                  v-b-tooltip.hover.top="'Upload CSV'"
                ></i
              ></a>
              <!-- v-b-modal.modal-box-2  -->
              <a
                href="javascript:;"
                class="btn-3"
                @click="saveDraftOrder('custom')"
                :class="{ disabled: activeProduct }"
                >{{ packageOrder ? 'Save as Draft' : 'Save to Draft'}}</a
              >
            </div>

            <OrderTotalBoxComponent :packageOrder="packageOrder" :da_20_count="da_20_count" :da_20_total="da_20_total" :da_30_count="da_30_count" :da_30_total="da_30_total"
          :da_40_count="da_40_count" :da_40_total="da_40_total" :da_50_count="da_50_count" :da_50_total="da_50_total"
          :da_60_count="da_60_count" :da_60_total="da_60_total" :links="links" :link_total="link_total" :addon_count="addon_count"
          :addon_total="addon_total" :total="total" :activeProduct="activeProduct" :showProceed="true" 
          @submit="submit"></OrderTotalBoxComponent>
          </div>
        </div>
        <CSVModal @on-upload="handleUploadedFile"></CSVModal>
      </div>
    </section>
  </div>
</template>

<style></style>

<script>
import { useToast } from "vue-toastification";
const toast = useToast();
import HeaderView from "@/components/layout/HeaderComponent.vue";
import CSVModal from "@/components/orderLinksComponents/AllModals.vue";
import LoaderComponent from "@/components/LoaderComponent.vue";
import OrderTotalBoxComponent from "@/components/OrderTotalBoxComponent.vue";
import { numberFormat } from "@/prod.js";
import swal from "sweetalert2";
import moment from "moment";
import jQuery from "jquery";
import LZString from "lz-string";
import url from "@/prod.js";

export default {
  name: "ProductView",
  components: {
    HeaderView,
    OrderTotalBoxComponent,
    CSVModal,
    LoaderComponent,
  },
  async mounted() {
    this.checkUrlParams();
    this.timer = setInterval(() => {
      this.saveDraftOrder();
    }, 20000);
    localStorage.removeItem("draft-order-id");
    var url = window.location.pathname;
    var id = url.substring(url.lastIndexOf("/") + 1);
    if (this.$route.params.id) {
      this.loaded = true;
      this.form.draft_order_id = id;
      localStorage.setItem("draft-order-id", id);
      await this.getProduct();
      await this.getAddonProduct();
      this.getEditLinks(id);
    } else {
      await this.getProduct();
      await this.getAddonProduct();
      this.addLink();
      this.loaded = false;
      const $ = jQuery;
      window.$ = $;
    }
    if(this.webOrder){
      this.webOrderLinksEdit();
    }
  },
  data() {
    return {
      numberFormat,
      LZString,
      appURL: url.appUrl,
      currentDate: moment(new Date()).format("DD/MM/YYYY"),
      apiResponseDraft: false,
      loaderText: "",
      timer: null,
      HyperTargetingTitle:
        "HOW HYPER TARGETING WORKS \nWith hyper-targeting, you now have more control over your link placement. Here’s how it works:",
      ContentTotal: 0,
      trialDA: false,
      counter: 0,
      links: [],
      orders: [],
      products: [],
      isShow: false,
      loaded: true,
      draft_links: [],
      addon_products: [],
      category_da_cost: "",
      category_cost: "",
      form: {
        id: "",
        product: [],
        addons: [],
        text: "",
        url: "",
        primary_category: [],
        da_fixed_addons: null,
        da_percentage_addons: null,
        fixed_addons: null,
        percentage_addons: null,
        secondry_category: "",
        selected_category: "",
        trialDA: false,
        isDaAddonFixed: true,
        isDaAddonPercentage: true,
        isAddon: true,
        isCopy: true,
        isDaAddonActive: false,
        isAddonActive: false,
        draft_order_id: "",
        order_title: "",
        total: 0,
      },
      draftOrder: [],
      categories: [],
      addon_da_options: [],
      addon_options: [],
      validations: {
        links: [],
      },
      errors: [],
      errors_validation: {
        order_title: [],
      },
      draft_order_id: "",
      activeProduct: true,
      activeAddonOption: true,
      link_total: 0,
      addon_total: 0,
      addon_count: 0,
      da_20_count: 0,
      da_20_total: 0,
      da_30_count: 0,
      da_30_total: 0,
      da_40_count: 0,
      da_40_total: 0,
      da_50_count: 0,
      da_50_total: 0,
      da_60_count: 0,
      da_60_total: 0,
      total: 0,
      uploadedData: {
        index: "",
        product_name: "",
        category_name: "",
        option_name: "",
      },
      draft_reloaded: "",
      disabledMinimumTrafic: [],
      mainProducts: {},
      queryParam: false,
      queryParamValue: "",
      daVal: "",
      catgVal: "",
      medium: "direct",
      source: "direct",
      selected_opportunities: [],
      order_type: 'normal',
      packageOrder: false,
      package_id: 0,
      packageName: '',
      package_product: '',
      package_category: '',
      package_price: 0,
      package_links: 0,
      webOrder: false,
      webOrderLinks: [],
      verifiedUser: false,
    };
  },
  methods: {
    getQueryParameterValue(parameterName) {
      var queryString = location.search.substring(1);
      var queryParams = queryString.split("&");

      for (var i = 0; i < queryParams.length; i++) {
        var param = queryParams[i].split("=");
        if (param[0] === parameterName) {
          return decodeURIComponent(param[1]);
        }
      }
      return null;
    },
    checkUrlParams() {
      var daValue = this.getQueryParameterValue("da");
      var categoryValue = this.getQueryParameterValue("category");
      var sourceValue = this.getQueryParameterValue("utm_source");
      var mediumValue = this.getQueryParameterValue("utm_medium");
      var campaignValue = this.getQueryParameterValue("utm_campaign");
      var webOrder = this.getQueryParameterValue("order");
      if(webOrder){
        this.webOrder = true;
        this.webOrderLinks = JSON.parse(atob(webOrder));
      }

      if (daValue !== null && categoryValue !== null) {
        this.daVal = daValue;
        this.catgVal = categoryValue;
        this.queryParamValue = `?da=${daValue}&category=${categoryValue}`;
        if (sourceValue) {
          this.source = sourceValue;
          this.queryParamValue += `&utm_source=${sourceValue}`;
        }
        if (mediumValue) {
          this.medium = mediumValue;
          this.queryParamValue += `&utm_medium=${mediumValue}`;
        }
        if (campaignValue) {
          this.queryParamValue += `&utm_campaign=${campaignValue}`;
        }
        this.queryParam = true;
      }

      if(!this.verifiedUser){
        if (this.userData.emailVerified) {
          this.verifiedUser = true;
        }else{
          this.verifiedUser = false;
        }
      }
    },
    webOrderLinksEdit() {
      this.webOrderLinks.forEach((row) => {
        var current_counter = this.counter;
        if (this.links[current_counter - 1].product == null) {
          current_counter = current_counter - 1;
        } else {
          this.addLink();
        }
        this.uploadedData.product_name = row.product_name;
        var product = [];
        if (row.product_name != null) {
          product = this.productFilteredList;
        }
        if (product.length > 0) {
          this.links[current_counter].product = product[0];
          this.getCategories(current_counter);
          this.getAddons(current_counter);
          this.uploadedData.index = current_counter;
          this.uploadedData.category_name = row.category_name;

          var category = [];
          if (row.category_name != null) {
            category = this.categoryFilteredList;
          }

          if (category.length > 0) {
            this.links[current_counter].primary_category = category[0];
            this.setSelected(current_counter);
            this.getAddons(current_counter);
            this.links[current_counter].isDaAddonActive = true;
            this.links[current_counter].isAddonActive = true;
            this.isShow = true;
            this.links[current_counter].isAddon = false;
            this.links[current_counter].isCopy = false;

            if (row.addons) {
              row.addons.forEach((row, index) => {
                this.uploadedData.option_name = row["name"];
                var addon_option = this.addonOptionFilteredList;

                if (addon_option.length > 0) {
                  this.links[current_counter].addons[index] = addon_option[0];
                  // this.addon_count += 1;
                  jQuery(document).ready(function () {
                    var currenttab = ".tabitemfield" + current_counter;
                    jQuery(currenttab + " .selectedCheckbox").each(function () {
                      jQuery(this).prop("checked") == true;
                      jQuery(this).parent().addClass("bordered");
                    });
                  });
                }

                this.uploadedData.option_name = row["name"];
                var addon_da_options = this.daAddonOptionFilteredList;

                if (addon_da_options.length > 0) {
                  this.links[current_counter].addons[index] =
                    addon_da_options[0];
                  this.links[current_counter].da_fixed_addons = JSON.stringify(
                    addon_da_options[0]
                  );
                }
                this.addLinkToOrder(current_counter);
              });
            }
          }
        }
        this.links[current_counter].text = "";
        this.links[current_counter].url = "";
        this.links[current_counter].secondry_category = "";
        this.links[current_counter].draft_order_id = "";
        this.links[current_counter].order_title = "";
      });

      this.countDA();
    },
    submit() {
      this.loaded = true;
      setTimeout(() => {
        this.proceed();
      }, 1000);
    },
    proceed() {
      if (!this.verifiedUser) {
        this.loaded = false;
        swal.fire({
          icon: "warning",
          text: "Please verify your email!",
        });
      }
      else if (this.form.order_title == "") {
        this.errors["order_title"] = ["Please enter valid order title"];
        this.loaded = false;
        swal.fire({
          icon: "warning",
          text: "Please fill order title first!",
          willClose: () => {
            this.handleValidationScroll();
          },
        });
      } else {
        var errCount = 0;
        var urlErrCount = 0;
        this.links.forEach((value, index) => {
          if (value.product == null) {
            this.validations.links[index].product.is_valid = true;
            this.validations.links[index].product.error = "Select Product";
            errCount += 1;
          }
          if (value.primary_category == null) {
            this.validations.links[index].primary_category.is_valid = true;
            this.validations.links[index].primary_category.error =
              "Select Category";
            errCount += 1;
          }
          if (
            value.secondry_category == "" ||
            value.secondry_category == undefined
          ) {
            this.validations.links[index].secondry_category.is_valid = true;
            this.validations.links[index].secondry_category.error =
              "Enter Secondary Category";
            errCount += 1;
          }
          if (value.text == "" || value.text == undefined) {
            this.validations.links[index].text.is_valid = true;
            this.validations.links[index].text.error = "Enter Anchor Text";
            errCount += 1;
          }
          if (value.url == "" || value.url == undefined) {
            this.validations.links[index].url.is_valid = true;
            this.validations.links[index].url.error = "Enter Destination Url";
            errCount += 1;
          }
        });

        if (errCount > 0) {
          this.loaded = false;
          swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Please fill all the required highlighted fields!",
            willClose: () => {
              this.handleValidationScroll();
            },
          });
        } else {
          this.links.forEach((value, index) => {
            if (value.url != "" || value.url != undefined) {
              const destURL = value.url;
              if (!this.isValidURL(destURL.trim().toLowerCase())) {
                this.validations.links[index].url.is_valid = true;
                urlErrCount += 1;
              }
            }
          });
          if (urlErrCount > 0) {
            this.loaded = false;
            swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Please enter a valid URL!",
              willClose: () => {
                this.handleValidationScroll();
              },
            });
          } else {
            localStorage.setItem("order-title", this.form.order_title);
            localStorage.setItem(
              "links",
              LZString.compress(JSON.stringify(this.links))
            );
            localStorage.setItem("da_20_count", this.da_20_count);
            localStorage.setItem("da_20_total", this.da_20_total);
            localStorage.setItem("da_30_count", this.da_30_count);
            localStorage.setItem("da_30_total", this.da_30_total);
            localStorage.setItem("da_40_count", this.da_40_count);
            localStorage.setItem("da_40_total", this.da_40_total);
            localStorage.setItem("da_50_count", this.da_50_count);
            localStorage.setItem("da_50_total", this.da_50_total);
            localStorage.setItem("da_60_count", this.da_60_count);
            localStorage.setItem("da_60_total", this.da_60_total);
            localStorage.setItem("links-total", this.link_total);
            localStorage.setItem("addons-count", this.addon_count);
            localStorage.setItem("addons-total", this.addon_total);
            localStorage.setItem("sub-total", this.total);
            localStorage.setItem("order-total", this.total);
            localStorage.removeItem("discount");
            localStorage.removeItem("couponCode");
            localStorage.setItem("selected-opportunity", this.selected_opportunities);
            localStorage.setItem("packageOrder", this.packageOrder);
            localStorage.setItem("packageName", this.packageName);
            localStorage.setItem("packageId", this.package_id);
            localStorage.setItem("packagePrice", this.package_price);
            localStorage.setItem("packageLinks", this.package_links);
            // For GA
            localStorage.setItem(
              "main_products",
              JSON.stringify(this.mainProducts)
            );
            if (this.queryParam) {
              window.location = this.appURL + "/order-summary?source=ppc";
              window.location =
                this.appURL +
                `/order-summary?utm_source=${this.source}&utm_medium=${this.medium}`;
            } else if (this.source != "direct" && this.medium != "direct") {
              window.location =
                this.appURL +
                `/order-summary?utm_source=${this.source}&utm_medium=${this.medium}`;
            } else {
              window.location = this.appURL + "/order-summary";
            }
          }
        }
      }
    },
    handleValidationScroll() {
      setTimeout(() => {
        if (this.form.order_title == "") {
          const invalidField = document.querySelector(".order-title-error");
          invalidField.scrollIntoView({ behavior: "smooth" });
        } else {
          const invalidField = document.querySelector(".has-error");
          invalidField.scrollIntoView({ behavior: "smooth" });
        }
      }, 500);
    },
    isValidURL(url) {
      var isValid = "";
      var match = url.match(/(?:https:\/\/)?(?:www\.)?(.*?)\./);
      const extractedValue = match ? match[1] : "";
      if (/^w+$/.test(extractedValue) && extractedValue.length > 3) {
        isValid = false;
      } else {
        if (/^w+$/.test(extractedValue) && extractedValue.length == 3) {
          const urlPattern =
            /^(?:(?:(?:https?|ftp):\/\/)?(?:www\.)?)?(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,}))(?:\/(?:[^\s/]*\/)?)?)?(?:\?(?:\S*))?(?:\.(?![^\s.]*$))?$/i;
          isValid = urlPattern.test(url);
        } else {
          const urlPattern =
            /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:(?!www)[/?#]\S*)?$/;
          isValid = urlPattern.test(url);
        }
      }
      return isValid;
    },
    async saveDraftOrder(saveByUser) {
      if (this.form.order_title == "" && saveByUser != "custom") {
        this.form.order_title = "Draft Autosaved " + this.currentDate;
      }
      if (saveByUser == "custom") {
        this.loaded = true;
        if (saveByUser == "custom" && this.form.order_title == "") {
          this.loaded = false;
          swal.fire({
            icon: "warning",
            text: "Please fill order title first!",
            willClose: () => {
              this.handleValidationScroll();
            },
          });
        }
      }
      try {
        var vm = this;
        vm.draft_links = [];

        vm.links.forEach((link) => {
          vm.draft_links.push({
            product_name: link.product ? link.product.name : "",
            text: link.text,
            url: link.url,
            category_name: link.primary_category
              ? link.primary_category.name
              : "",
            secondry_category: link.secondry_category,
            addons: link.addons,
            order_title: this.form.order_title,
            draft_order_id: this.form.draft_order_id,
          });
        });

        let res = await this.$store.dispatch(
          "Product/saveDraftOrderAction",
          {
            order_type: this.order_type,
            order_title: this.form.order_title,
            total: this.total,
            links: this.draft_links,
            order_medium: this.medium,
            order_source: this.source,
            package_id: this.package_id,
          },
          {
            root: true,
          }
        );
        if (res.success) {
          if (saveByUser == "custom") {
            this.loaded = false;
            toast.success(res.message, {
              timeout: 3000,
            });
            if(this.packageOrder){
              window.location = this.appURL + "/packages";
            }else{
              window.location = this.appURL + "/draft-order";
            }
          } else {
            if (this.form.draft_order_id != res.draft_order_id) {
              const newPathname = "/product/" + res.draft_order_id;
              this.$router.replace(newPathname);
              this.form.draft_order_id = res.draft_order_id;
            } else if (!this.$route.params.id) {
              localStorage.setItem("draft-order-id", res.draft_order_id);
              this.draft_order_id = res.draft_order_id;
              if (window.location.pathname.endsWith("/product/")) {
                if (this.queryParam) {
                  this.$router.replace(
                    window.location.pathname +
                      res.draft_order_id +
                      this.queryParamValue
                  );
                } else {
                  this.$router.replace(
                    window.location.pathname + res.draft_order_id
                  );
                }
              } else {
                if (this.queryParam) {
                  this.$router.replace(
                    window.location.pathname +
                      "/" +
                      res.draft_order_id +
                      this.queryParamValue
                  );
                } else {
                  this.$router.replace(
                    window.location.pathname + "/" + res.draft_order_id
                  );
                }
              }
            }
          }
        }
      } catch (error) {
        this.loaded = false;
        this.errors = error.response.data.errors;
      }
    },
    async getProduct() {
      try {
        let res = await this.$store.dispatch("Product/getProductDataAction");
        this.products = res.products;
      } catch (e) {
        this.loaded = false;
      }
    },
    async getAddonProduct() {
      try {
        let res = await this.$store.dispatch(
          "Product/getAddonProductDataAction"
        );
        this.addon_products = res.addon_products;
      } catch (e) {
        this.loaded = false;
      }
    },

    async getEditLinks(id) {
      try {
        let res = await this.$store.dispatch("Link/getEditLinksDataAction", id);
        if (res.draft_order) {
          this.draftOrder = res.draft_order;
          var links = JSON.parse(res.draft_order.links);
          if(res.draft_order.package_id > 0){
            this.order_type = 'package';
            this.packageOrder = true;
            this.package_id = res.draft_order.package_id;
            this.packageName = res.package_name;
            this.package_product = links[0].product_name;
            this.package_category = links[0].category_name;
            this.package_price = res.amount;
            this.package_links = links.length;
          }
          var draft_order_id = res.draft_order.id;
          this.source = res.draft_order.order_source;
          this.medium = res.draft_order.order_medium;
          if (this.source != "direct" && this.medium != "direct") {
            this.$router.replace(
              window.location.pathname +
                `?utm_source=${this.source}&utm_medium=${this.medium}`
            );
          }
          this.editLink(links, draft_order_id);
        } else {
          toast.error("Draft Order Already Deleted", {
            timeout: 3000,
          });
        }
        this.loaded = false;
      } catch (e) {
        if (e.response.data.error == "Access Denied") {
          localStorage.removeItem("draft-order-id");
          this.$router.push("/dashboard");
        }
        this.loaded = false;
      }
    },
    editLink(links, draft_order_id) {
      links.forEach((row) => {
        this.form.order_title = this.draftOrder.order_title;
        var current_counter = this.counter;
        this.uploadedData.product_name = row["product_name"];
        var product = [];
        if (row["product_name"] != null) {
          product = this.productFilteredList;
        }
        this.addLink();
        if (product.length > 0) {
          this.links[current_counter].product = product[0];
          this.getCategories(current_counter);
          this.getAddons(current_counter);
          this.uploadedData.index = current_counter;
          this.uploadedData.category_name = row["category_name"];

          var category = [];
          if (row["category_name"] != null) {
            category = this.categoryFilteredList;
          }

          if (category.length > 0) {
            this.links[current_counter].primary_category = category[0];
            this.setSelected(current_counter);
            this.getAddons(current_counter);
            this.links[current_counter].isDaAddonActive = true;
            this.links[current_counter].isAddonActive = true;
            this.isShow = true;
            this.links[current_counter].isAddon = false;
            this.links[current_counter].isCopy = false;

            if (row.addons) {
              row.addons.forEach((row, index) => {
                this.uploadedData.option_name = row["name"];
                var addon_option = this.addonOptionFilteredList;

                if (addon_option.length > 0) {
                  this.links[current_counter].addons[index] = addon_option[0];
                  // this.addon_count += 1;
                  jQuery(document).ready(function () {
                    var currenttab = ".tabitemfield" + current_counter;
                    jQuery(currenttab + " .selectedCheckbox").each(function () {
                      jQuery(this).prop("checked") == true;
                      jQuery(this).parent().addClass("bordered");
                    });
                  });
                }

                this.uploadedData.option_name = row["name"];
                var addon_da_options = this.daAddonOptionFilteredList;

                if (addon_da_options.length > 0) {
                  this.links[current_counter].addons[index] =
                    addon_da_options[0];
                  this.links[current_counter].da_fixed_addons = JSON.stringify(
                    addon_da_options[0]
                  );
                }
                this.addLinkToOrder(current_counter);
              });
            }
          }
        }
        this.links[current_counter].text = row["text"];
        this.links[current_counter].url = row["url"];
        this.links[current_counter].secondry_category =
          row["secondry_category"];
        this.links[current_counter].draft_order_id = draft_order_id;
        this.links[current_counter].order_title = this.draftOrder.order_title;
      });

      this.countDA();
    },

    addLink() {
      this.links.push({
        ...this.form,
      });
      this.links[this.counter].product = null;
      this.links[this.counter].primary_category = null;
      this.links[this.counter].addons = [];

      this.validations.links.push({
        product: {
          is_valid: false,
        },
        text: {
          is_valid: false,
        },
        url: {
          is_valid: false,
        },
        primary_category: {
          is_valid: false,
        },
        secondry_category: {
          is_valid: false,
        },
      });

      this.links[this.counter].id =
        "_" + Math.random().toString(36).substr(2, 9);

      if (this.queryParam) {
        this.products.forEach((product) => {
          const numericValue = parseInt(product.name.match(/\d+/)[0]);
          if (this.daVal.includes(numericValue.toString())) {
            this.links[this.counter].product = product;
            this.setPD(this.links[this.counter].product, this.counter);
            this.getCategories(this.counter);
          }
        });
      }

      ++this.counter;
    },

    removeLink(key) {
      if (this.counter > 1) {
        if (this.activeProduct == true) {
          this.activeProduct = false;
        }
        --this.counter;
        this.removeOrder(this.links[key]);
        this.links.splice(key, 1);
        this.categories.splice(key, 1);
        this.addon_options.splice(key, 1);
        this.addon_da_options.splice(key, 1);
        this.validations.links.splice(key, 1);
        this.countDA();
      }
    },

    setTrialDA() {
      this.links.some((e) => {
        if (e.product.name === "DA 30+") {
          e.trialDA = true;
          return true;
        }
      });
    },
    removeOrder(link) {
      this.orders.forEach((o, i) => {
        if (o.id == link.id) {
          this.orders.splice(i, 1);
        }
      });
      this.updateTotal();
    },

    updateTotal() {
      this.total = 0;
      this.link_total = 0;
      this.addon_total = 0;
      this.orders.forEach((o) => {
        if (o.link_total > 0) {
          this.total += o.link_total;
          this.link_total += o.link_total;
        }
        if (o.da_addon_total > 0) {
          this.total += o.da_addon_total;
          this.addon_total += o.da_addon_total;
        }
        if (o.addon_total > 0) {
          this.total += o.addon_total;
          this.addon_total += o.addon_total;
        }
      });
      this.addonCount();
    },

    copyLink(key) {
      if (
        this.links[key].isDaAddonActive == true &&
        this.links[key].isAddonActive == true
      ) {
        var new_link = JSON.parse(JSON.stringify(this.links[key]));
        new_link.id = "_" + Math.random().toString(36).substr(2, 9);
        let new_key = key + 1;
        this.links.splice(key, 0, new_link);
        this.links[new_key].trialDA = false;
        this.addon_options.splice(key, 0, this.addon_options[key]);
        this.addon_da_options.splice(key, 0, this.addon_da_options[key]);
        this.categories.splice(key, 0, this.categories[key]);
        this.validations.links.splice(key, 0, {
          product: {
            is_valid: false,
          },
          text: {
            is_valid: false,
          },
          url: {
            is_valid: false,
          },
          primary_category: {
            is_valid: false,
          },
          secondry_category: {
            is_valid: false,
          },
        });
        ++this.counter;
        if (
          Object.entries(new_link.primary_category).length !== 0 &&
          new_link.primary_category.constructor === Object
        ) {
          this.addLinkToOrder(key);
        }

        this.countDA();
      }
    },
    addLinkToOrder(key) {
      this.removeOrder(this.links[key]);
      if (this.chkDA30count() == 0) {
        this.links[key].trialDA = true;
      }
      
      let order_total = 0;
      if(this.packageOrder){
          order_total = this.package_price / this.package_links;
      }else{
          order_total = this.getOrderTotal(key);
      }
      let addon_total = this.getAddonTotal(key);
      this.orders.splice(key, 0, {
        id: this.links[key].id,
        product_id: this.links[key].product.id,
        product_name: this.links[key].product.name,
        link: this.links[key],
        link_total: order_total,
        addon_total: addon_total,
        trialDA: this.trialDA,
        order_title: this.form.order_title,
        draft_order_id: this.form.draft_order_id,
      });
      this.links[key].total = order_total + addon_total;
      this.updateTotal();
    },

    getOrderTotal(key) {
      let order_total = 0;
      if (this.links[key].primary_category != undefined) {
        var cost =
          this.links[key].primary_category.cost + this.links[key].product.cost;
        if (this.links[key].primary_category.special == 1) {
          order_total += Math.round(cost / 1.1);
        } else {
          order_total +=
            (cost + "=>",
            cost % 5 < 3
              ? cost % 5 === 0
                ? cost
                : Math.floor(cost / 5) * 5
              : Math.ceil(cost / 5) * 5);
        }
      }
      // var a = false;
      // if(a == false && this.links[key].trialDA && this.userData.trial == 0 && this.userData.order_count == 0 && this.links[key].product.name == 'DA 30+' && this.links[key].primary_category != undefined){
      //     this.trialDA = true;
      //     order_total = 5;
      // }
      return order_total;
    },

    getAddonTotal(key) {
      let addon_total = 0;
      if (
        this.links[key].addons != undefined &&
        this.links[key].addons.length > 0
      ) {
        this.links[key].addons.forEach((addon) => {
          addon_total += addon.cost;
        });
      }

      return addon_total;
    },
    setText(value, index) {
      if (value.length > 0) {
        this.validations.links[index].text.is_valid = false;
      } else {
        this.validations.links[index].text.is_valid = true;
      }
    },
    setPD(value, index) {
      if (value.length > 0 || this.queryParam) {
        this.validations.links[index].product.is_valid = false;
      } else {
        this.validations.links[index].product.is_valid = true;
      }
      var NewAddon = this.links[index].addons.filter((o) => {
        o.category_id != this.links[index].selected_category.id;
      });

      this.links[index].addons = NewAddon;
      this.links[index].da_fixed_addons = null;
      this.links[index].da_percentage_addons = null;
      this.links[index].fixed_addons = null;
      this.links[index].percentage_addons = null;
      this.addLinkToOrder(index);
      this.countDA();
    },
    getCategories(key) {
      let categories = this.getPrimaryCategories(key);
      this.categories[key] = categories;
      if (this.queryParam) {
        this.categories[key].filter((category) => {
          if (category.id == this.catgVal) {
            this.links[key].primary_category = category;
          }
        });
        if (this.links[key].primary_category != null) {
          this.setPC(this.links[key].primary_category, key);
          this.setSelected(key);
        }
      }
      if (this.links[key].selected_category <= 0) {
        this.addLinkToOrder(key);
      } else {
        this.links[key].primary_category = this.categories[key].filter(
          (category) => category.id === this.links[key].selected_category
        )[0];
        this.getAddons(key);
      }

      if (this.activeProduct == true) {
        this.activeProduct = false;
      }
      this.countDA();
    },
    StatusActive(key, event) {
      if (
        this.links[key].isDaAddonActive == true &&
        this.links[key].isAddonActive == true
      ) {
        jQuery(".activeAddon" + key).toggle();
        if (jQuery(event.target).hasClass("fa-angle-down")) {
          jQuery(event.target).removeClass("fa-angle-down");
          jQuery(event.target).addClass("fa-angle-up");
        } else {
          jQuery(event.target).removeClass("fa-angle-up");
          jQuery(event.target).addClass("fa-angle-down");
        }
      }
    },
    setSC(value, index) {
      if (value.length > 0) {
        this.validations.links[index].secondry_category.is_valid = false;
      } else {
        this.validations.links[index].secondry_category.is_valid = true;
      }
    },
    setUrl(value, index) {
      if (value.length > 0) {
        this.validations.links[index].url.is_valid = false;
      } else {
        this.validations.links[index].url.is_valid = true;
      }
    },
    setPC(value, index) {
      if (value.length > 0 || this.queryParam) {
        this.validations.links[index].primary_category.is_valid = false;
      } else {
        this.validations.links[index].primary_category.is_valid = true;
      }

      if (this.links[index].isAddon == true) {
        this.links[index].isAddon = false;
      }

      if (this.links[index].isCopy == true) {
        this.links[index].isCopy = false;
      }

      if (this.links[index].isAddonActive == false) {
        this.links[index].isAddonActive = true;
      }

      if (this.links[index].isDaAddonActive == false) {
        this.links[index].isDaAddonActive = true;
      }

      var NewAddon = this.links[index].addons.filter((o) => {
        o.category_id != this.links[index].selected_category.id;
      });
      this.links[index].addons = NewAddon;
      this.links[index].da_fixed_addons = null;
      this.links[index].da_percentage_addons = null;
      this.links[index].fixed_addons = null;
      this.links[index].percentage_addons = null;
      this.addLinkToOrder(index);
      this.countDA();
    },
    setMA(value, index) {
      this.addLinkToOrder(index);
    },
    setSA(value, index) {
      this.addLinkToOrder(index);
    },
    setNDSA(value, index) {
      this.addLinkToOrder(index);
    },
    getPrimaryCategories(key) {
      let primary_categories = [];
      this.links[key].product.categories.forEach((cat) => {
        var cost = cat.pivot.cost + this.links[key].product.cost;
        var total_cost = 0;
        if (cat.special == 1) {
          total_cost = Math.round(cost / 1.1);
        } else {
          total_cost =
            (cost + "=>",
            cost % 5 < 3
              ? cost % 5 === 0
                ? cost
                : Math.floor(cost / 5) * 5
              : Math.ceil(cost / 5) * 5);
        }
        primary_categories.push({
          id: cat.id,
          name: cat.name,
          cost: cat.pivot.cost,
          product_cost: this.links[key].product.cost,
          total_cost: total_cost,
          special: cat.special,
        });
      });
      return primary_categories;
    },
    getAddons(key) {
      let addon_options = this.getAddonOption(key);
      this.addon_options[key] = addon_options;
      let addon_da_options = this.getAddonDaOption(key);
      this.addon_da_options[key] = addon_da_options;
      if (this.links[key].addons <= 0) {
        this.addLinkToOrder(key);
      }
    },
    setSelected(key) {
      this.links[key].selected_category = this.links[key].primary_category.id;
    },
    setSelectedMAddon(id, key, index) {
      var addon_id = id;
      var MultiAddon = JSON.parse(index);
      if (MultiAddon != null) {
        var NewMAddon = this.links[key].addons.filter((o) => {
          return o.addon_id != MultiAddon.addon_id;
        });
        this.links[key].addons = NewMAddon;
        this.links[key].addons.push(MultiAddon);
      } else {
        var delete_addon = this.links[key].addons.findIndex(
          (item) => item.addon_id === addon_id
        );
        this.links[key].addons.splice(delete_addon, 1);
      }
      this.addLinkToOrder(key);
    },

    setSelectedSAddon(key, checked, option) {
      if (checked == true) {
        this.links[key].addons.push(option);
      } else {
        var NewAddon = this.links[key].addons.filter((o) => {
          return o.id != option.id;
        });
        this.links[key].addons = NewAddon;
      }
      this.addLinkToOrder(key);
    },
    setSelectedNDAddon(id, key, index) {
      var addon_id = id;
      var MultiAddon = JSON.parse(index);
      if (MultiAddon != null) {
        var NewMAddon = this.links[key].addons.filter((o) => {
          return o.addon_id != MultiAddon.addon_id;
        });
        this.links[key].addons = NewMAddon;
        this.links[key].addons.push(MultiAddon);
      } else {
        var delete_addon = this.links[key].addons.findIndex(
          (item) => item.addon_id === addon_id
        );
        this.links[key].addons.splice(delete_addon, 1);
      }
      this.addLinkToOrder(key);
    },
    setSelectedNDSSAddon(key, checked, option) {
      if (checked == true) {
        this.links[key].addons.push(option);
      } else {
        var NewAddon = this.links[key].addons.filter((o) => {
          return o.id != option.id;
        });
        this.links[key].addons = NewAddon;
      }
      this.addLinkToOrder(key);
    },
    getAddonDaOption(key) {
      this.links[key].isDaAddonPercentage = true;
      this.links[key].isDaAddonFixed = true;
      let addon_da_options = [];
      this.categories.forEach((category) => {
        category.forEach((cat) => {
          if (cat.id == this.links[key].selected_category) {
            this.category_da_cost = cat.cost + this.links[key].product.cost;
          }
        });
      });
      var addon_percentage = 0;
      var category_id;
      var product_id;
      this.links[key].product.addons.forEach((opt) => {
        if (
          opt.addons_data.price_type == "percentage" &&
          opt.addons_data.dependent_da == "yes"
        ) {
          if (
            this.links[key].primary_category != undefined &&
            this.links[key].product != undefined
          ) {
            addon_percentage =
              this.category_da_cost * (opt.pivot.cost / 100).toFixed(2);

            category_id = this.links[key].primary_category.id;
            product_id = this.links[key].product.id;
            if (addon_percentage != 0) {
              this.addon_products.forEach((addon) => {
                if (opt.addon_id == addon.id) {
                  this.links[key].isDaAddonPercentage = false;
                }
              });
              addon_da_options.push({
                id: opt.id,
                addon_id: opt.addon_id,
                type: opt.addons_data.type,
                price_type: opt.addons_data.price_type,
                dependent_da: opt.addons_data.dependent_da,
                addon_name: opt.addons_data.name,
                name: opt.name,
                cost: Math.round(addon_percentage),
                product_id: product_id,
                category_id: category_id,
              });
            }
          }
        }

        if (
          opt.addons_data.price_type == "fixed" &&
          opt.addons_data.dependent_da == "yes" &&
          opt.name != '100000'
        ) {
          if (
            this.links[key].primary_category != undefined &&
            this.links[key].product != undefined
          ) {
            addon_percentage = opt.pivot.cost;

            category_id = this.links[key].primary_category.id;
            product_id = this.links[key].product.id;
            if (addon_percentage != 0) {
              this.addon_products.forEach((addon) => {
                if (opt.addon_id == addon.id) {
                  this.links[key].isDaAddonFixed = false;
                }
              });
              addon_da_options.push({
                id: opt.id,
                addon_id: opt.addon_id,
                type: opt.addons_data.type,
                price_type: opt.addons_data.price_type,
                dependent_da: opt.addons_data.dependent_da,
                addon_name: opt.addons_data.name,
                name: opt.name,
                cost: Math.round(addon_percentage),
                product_id: product_id,
                category_id: category_id,
              });
            }
          }
        }
      });
      return addon_da_options;
    },

    getAddonOption(key) {
      let addon_options = [];
      this.categories.forEach((category) => {
        category.forEach((cat) => {
          if (cat.id == this.links[key].selected_category) {
            this.category_cost = cat.cost + this.links[key].product.cost;
          }
        });
      });
      var addon_percentage;
      var product_id;
      var category_id;
      this.addon_products.forEach((addon) => {
        addon.options.forEach((opt) => {
          if (
            opt.addons_data.price_type == "percentage" &&
            opt.addons_data.dependent_da == "no"
          ) {
            if (
              this.links[key].primary_category != undefined &&
              this.links[key].product != undefined
            ) {
              addon_percentage =
                this.category_cost * (opt.price / 100).toFixed(2);
              category_id = this.links[key].primary_category.id;
              product_id = this.links[key].product.id;
              addon_options.push({
                id: opt.id,
                addon_id: opt.addon_id,
                type: opt.addons_data.type,
                price_type: opt.addons_data.price_type,
                dependent_da: opt.addons_data.dependent_da,
                addon_name: opt.addons_data.name,
                name: opt.name,
                cost: Math.round(addon_percentage),
                product_id: product_id,
                category_id: category_id,
              });
            }
          }

          if (
            opt.addons_data.price_type == "fixed" &&
            opt.addons_data.dependent_da == "no" &&
            opt.name != '100000'
          ) {
            if (
              this.links[key].primary_category != undefined &&
              this.links[key].product != undefined
            ) {
              category_id = this.links[key].primary_category.id;
              product_id = this.links[key].product.id;
              addon_percentage = opt.price;
            }
            addon_options.push({
              id: opt.id,
              addon_id: opt.addon_id,
              type: opt.addons_data.type,
              price_type: opt.addons_data.price_type,
              dependent_da: opt.addons_data.dependent_da,
              addon_name: opt.addons_data.name,
              name: opt.name,
              cost: Math.round(addon_percentage),
              product_id: product_id,
              category_id: category_id,
            });
          }
        });
      });
      return addon_options;
    },

    chkDA30count() {
      let count = 0;
      this.orders.forEach((o) => {
        if (o.product_name == "DA 30+") {
          count++;
        }
      });
      return count;
    },

    handleUploadedFile(convertedData) {
      convertedData.body.forEach((row) => {
        var current_counter = this.counter;
        if (row["DOMAIN AUTHORITY"] != undefined) {
          //Check if previous link has any product added, if not then start adding from previous counter
          if (this.links[current_counter - 1].product == null) {
            current_counter = current_counter - 1;
          } else {
            this.addLink();
          }

          this.uploadedData.product_name = row["DOMAIN AUTHORITY"];
          var product = this.productFilteredList;
          if (product.length > 0) {
            this.links[current_counter].product = product[0];
            this.getCategories(current_counter);
            this.getAddons(current_counter);
            this.uploadedData.index = current_counter;
            if (row["PRIMARY CATEGORY"] != undefined) {
              this.uploadedData.category_name = row["PRIMARY CATEGORY"];
              var category = this.categoryFilteredList;
              if (category.length > 0) {
                this.links[current_counter].primary_category = category[0];
                this.setSelected(current_counter);
                this.getAddons(current_counter);
                this.links[current_counter].isDaAddonActive = true;
                this.links[current_counter].isAddonActive = true;
                this.isShow = true;
                this.links[current_counter].isAddon = false;
                this.links[current_counter].isCopy = false;
              }
              this.addLinkToOrder(current_counter);
            }
            this.links[current_counter].text = row["ANCHOR TEXT"];
            this.links[current_counter].url = row["DESTINATION URL"];
            this.links[current_counter].secondry_category =
              row["SECONDARY CATEGORY"];
          }
        }
      });
      this.countDA();
      this.addonCount();
      this.loaderText = "";
      this.loaded = false;
    },

    countDA() {
      this.da_20_count = 0;
      this.da_30_count = 0;
      this.da_40_count = 0;
      this.da_50_count = 0;
      this.da_60_count = 0;
      this.da_20_total = 0;
      this.da_30_total = 0;
      this.da_40_total = 0;
      this.da_50_total = 0;
      this.da_60_total = 0;
      var da_20_addon_count = 0;
      var da_20_addon_total = 0;
      var da_30_addon_count = 0;
      var da_30_addon_total = 0;
      var da_40_addon_count = 0;
      var da_40_addon_total = 0;
      var da_50_addon_count = 0;
      var da_50_addon_total = 0;
      var da_60_addon_count = 0;
      var da_60_addon_total = 0;
      this.links.forEach((val) => {
        if (val.product) {
          if (val.product.name == "DA 20 or Below") {
            this.da_20_count += 1;
            if (val.primary_category != null) {
              if(this.packageOrder){
                this.da_20_total = this.package_price;
              }else{
                this.da_20_total += val.primary_category.total_cost;
              }
            }
            if (val.addons) {
              val.addons.forEach((val2) => {
                da_20_addon_count += 1;
                da_20_addon_total += val2.cost;
              });
            }
            const da_20 = {
              product_name: val.product.name,
              link_cost: this.da_20_total,
              addon_cost: da_20_addon_total,
              total_price: this.da_20_total + da_20_addon_total,
              link_count: this.da_20_count,
              addon_count: da_20_addon_count,
            };
            this.mainProducts[val.product.name] = da_20;
          }
          if (val.product.name == "DA 30+") {
            this.da_30_count += 1;
            if (val.primary_category != null) {
              if(this.packageOrder){
                this.da_30_total = this.package_price;
              }else{
                this.da_30_total += val.primary_category.total_cost;
              }
            }
            if (val.addons) {
              val.addons.forEach((val2) => {
                da_30_addon_count += 1;
                da_30_addon_total += val2.cost;
              });
            }
            const da_30 = {
              product_name: val.product.name,
              link_cost: this.da_30_total,
              addon_cost: da_30_addon_total,
              total_price: this.da_30_total + da_30_addon_total,
              link_count: this.da_30_count,
              addon_count: da_30_addon_count,
            };
            this.mainProducts[val.product.name] = da_30;
          }
          if (val.product.name == "DA 40+") {
            this.da_40_count += 1;
            if (val.primary_category != null) {
              if(this.packageOrder){
                this.da_40_total = this.package_price;
              }else{
                this.da_40_total += val.primary_category.total_cost;
              }
            }
            if (val.addons) {
              val.addons.forEach((val2) => {
                da_40_addon_count += 1;
                da_40_addon_total += val2.cost;
              });
            }
            const da_40 = {
              product_name: val.product.name,
              link_cost: this.da_40_total,
              addon_cost: da_40_addon_total,
              total_price: this.da_40_total + da_40_addon_total,
              link_count: this.da_40_count,
              addon_count: da_40_addon_count,
            };
            this.mainProducts[val.product.name] = da_40;
          }
          if (val.product.name == "DA 50+") {
            this.da_50_count += 1;
            if (val.primary_category != null) {
              if(this.packageOrder){
                this.da_50_total = this.package_price;
              }else{
                this.da_50_total += val.primary_category.total_cost;
              }
            }
            if (val.addons) {
              val.addons.forEach((val2) => {
                da_50_addon_count += 1;
                da_50_addon_total += val2.cost;
              });
            }
            const da_50 = {
              product_name: val.product.name,
              link_cost: this.da_50_total,
              addon_cost: da_50_addon_total,
              total_price: this.da_50_total + da_50_addon_total,
              link_count: this.da_50_count,
              addon_count: da_50_addon_count,
            };
            this.mainProducts[val.product.name] = da_50;
          }
          if (val.product.name == "DA 60+") {
            this.da_60_count += 1;
            if (val.primary_category != null) {
              if(this.packageOrder){
                this.da_60_total = this.package_price;
              }else{
                this.da_60_total += val.primary_category.total_cost;
              }
            }
            if (val.addons) {
              val.addons.forEach((val2) => {
                da_60_addon_count += 1;
                da_60_addon_total += val2.cost;
              });
            }
            const da_60 = {
              product_name: val.product.name,
              link_cost: this.da_60_total,
              addon_cost: da_60_addon_total,
              total_price: this.da_60_total + da_60_addon_total,
              link_count: this.da_60_count,
              addon_count: da_60_addon_count,
            };
            this.mainProducts[val.product.name] = da_60;
          }
        }
      });
      this.addonCount();
    },

    addonCount() {
      this.addon_count = 0;
      this.links.filter((o) => {
        this.addon_count += o.addons.length;
      });
    },
  },
  watch: {
    categories: function (newV) {
      if (
        this.links[0].primary_category != undefined &&
        Object.entries(this.links[0].primary_category).length !== 0 &&
        this.links[0].primary_category.constructor === Object
      ) {
        this.links.forEach((l, i) => {
          if (newV[i] != undefined) {
            newV[i].forEach((o) => {
              if (l.selected_category == o.id) {
                this.$set(l, "primary_category", o);
                this.addLinkToOrder(i);
              }
            });
          }
        });
      }
    },
    userData(newVal) {
      if (newVal.emailVerified) {
        this.verifiedUser = true;
      }else{
        this.verifiedUser = false;
      }
    }
  },
  computed: {
    userData() {
      const data = this.$store.getters["Auth/getUser"];
      return {
        trial: data.trial,
        order_count: data.order_count,
        emailVerified: data.is_email_verified,
      };
    },
    getLinks() {
      return this.categories;
    },

    productFilteredList() {
      return this.products.filter((product) => {
        if (
          product.name.toLowerCase() ==
          this.uploadedData.product_name.trim().toLowerCase()
        )
          return true;
      });
    },
    categoryFilteredList() {
      return this.categories[this.uploadedData.index].filter((category) => {
        if (
          category.name.toLowerCase() ==
          this.uploadedData.category_name.trim().toLowerCase()
        )
          return true;
      });
    },
    daAddonOptionFilteredList() {
      return this.addon_da_options[this.uploadedData.index].filter((addon) => {
        if (addon.name == this.uploadedData.option_name) return true;
      });
    },
    addonOptionFilteredList() {
      return this.addon_options[this.uploadedData.index].filter((addon) => {
        if (addon.name == "Content Approval") {
          if (addon.name == this.uploadedData.option_name) return true;
        }
      });
    },
    setColor() {
      return (item) => {
        if (item) {
          this.isColor = "red";
        } else {
          this.isColor = "";
        }
        return { color: this.isColor };
      };
    },
  },
  beforeUnmount() {
    clearInterval(this.timer);
  },
};
</script>
